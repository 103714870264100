import { useEffect, useState } from "react"; 

import { BACKEND_ENDPOINT } from "./config";

function Test () {
    const [message, setMessage] = useState("Loading")
    useEffect(() => {
        fetch(`${BACKEND_ENDPOINT}/say-hello`)
            .then(r => r.json())
            .then(value => setMessage(value.message))
    }, []);
    return <h1>{message}</h1>;
}

export default Test;
